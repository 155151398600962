// // Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as material;

@include material.core();

/// Azure color palette to be used as primary or tertiary palette.
$custom-azure-palette: (
  50: #abc7ff,
  100: #7cabff,
  200: #438fff,
  300: #0074e9,
  400: #005cbb,
  500: #0050a5,
  600: #00458f,
  700: #003a7a,
  800: #002f65,
  900: #001b3f,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  )
);

$custom-orange-palette: (
  50: #ffb787,
  100: #fec448,
  200: #ffbf34,
  300: #ffb921,
  400: #ffa615,
  500: #feb009,
  600: #eea302,
  700: #d68202,
  800: #b97102,
  900: #b05f02,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  )
);

$app-primary: material.m2-define-palette($custom-azure-palette);
$app-accent: material.m2-define-palette($custom-orange-palette);
$app-warn: material.m2-define-palette(material.$m2-red-palette);

// extra Colors
$custom-success: material.m2-define-palette(material.$m2-green-palette);
$custom-danger: material.m2-define-palette(material.$m2-orange-palette);

$custom-theme: material.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);
@include material.all-component-themes($custom-theme);

.mat-success {
  background-color: material.m2-get-color-from-palette($custom-success, 500);
  color: material.m2-get-color-from-palette($custom-success, 500-contrast);
}


.mat-danger {
  background-color: material.m2-get-color-from-palette($custom-danger, 500);
  color: material.m2-get-color-from-palette($custom-danger, 500-contrast);
}
