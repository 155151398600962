// @use "@angular/material" as mat;

/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Chrome, Safari, Edge, Opera - para retirar flechas up and down no input de numeros  */
input[matinput]::-webkit-outer-spin-button,
input[matinput]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox  - para retirar flechas up and down no input de numeros */
input[matinput][type=number] {
  -moz-appearance: textfield;
}




// $custom-primary: mat.define-palette($custom-primary-palette);
// $custom-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
// $custom-warn: mat.define-palette(mat.$orange-palette, A200, A100, A400);

